import { HOTELS_TYPES_VALUES } from "@raiden/library-ui/constants/hotels";
import SearchInfiniteProvider from "@raiden/library-ui/contexts/SearchInfinite/SearchInfiniteProvider";
import { useSearchInfinite } from "@raiden/library-ui/contexts/SearchInfinite/useSearchInfinite";
import { getCampaignAttribute } from "@raiden/library-ui/helpers/campaigns/getAttribute";
import { isAnyDateAttributeSetInCampaign } from "@raiden/library-ui/helpers/campaigns/isAnyDateAttributeSet";
import { getBoolean } from "@raiden/library-ui/hooks/router/useRouterBoolean";
import { getNumber } from "@raiden/library-ui/hooks/router/useRouterNumber";
import { getNumberArray } from "@raiden/library-ui/hooks/router/useRouterNumberArray";
import { getString } from "@raiden/library-ui/hooks/router/useRouterString";
import { getStringArray } from "@raiden/library-ui/hooks/router/useRouterStringArray";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import dayjs from "dayjs";
import { memo, useState } from "react";
import {
  SEARCH_DATES_MODES_VALUES,
  SEARCH_DATES_MODES_VALUE_FLEXIBLE,
  SEARCH_DATES_MODES_VALUE_PRECISE,
  SEARCH_DATES_PRECISE_MODES,
  SEARCH_DATES_PRECISE_MODES_VALUES,
  SEARCH_DATES_PRECISE_MODES_VALUE_PRECISE,
  SEARCH_DURATIONS,
  SEARCH_DURATIONS_VALUES,
  SEARCH_DURATIONS_VALUE_1_WEEK,
  SEARCH_RANKING_VALUES,
  SEARCH_RANKING_VALUE_0,
  SEARCH_SORTS_VALUES,
} from "../../../constants/Search";
import { getAvailableMonths } from "../../../helpers/search/getAvailableMonths";
import encodeQuery from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeQuery";
import dateGetDaysCount from "@raiden/library-ui/helpers/date/getDaysCount";

// #region getValidDate
/**
 * @param {object} params
 * @param {any} params.value
 * @returns {string | undefined}
 */
export function getValidDate({ value }) {
  const date = dayjs(value);
  if (
    date.isValid() &&
    (date.isAfter(dayjs().startOf("day")) ||
      date.isSame(dayjs().startOf("day")))
  ) {
    return value;
  }
  return undefined;
}

// #region getMonth
/**
 * @param {object} params
 * @param {string} params.value
 */
export function getMonth({ value }) {
  if (getAvailableMonths().includes(value)) {
    return value;
  }
  return "";
}

// #region SearchFormValues
/**
 * @typedef {object} SearchFormValues
 * @property {import("../../Places/Search").PlacesSearchValue} location
 * @property {string} checkin
 * @property {string} checkout
 * @property {string} min_checkin
 * @property {string} max_checkin
 * @property {string} min_checkout
 * @property {string} max_checkout
 * @property {string} min_duration
 * @property {string} max_duration
 * @property {number} adults
 * @property {number} children
 * @property {number} babies
 * @property {number} pets
 * @property {string} min_price
 * @property {string} max_price
 * @property {number | ""} bedrooms
 * @property {number | ""} beds
 * @property {number | ""} bathrooms
 * @property {import("../../../types/Search").SearchSort} sort
 * @property {number[]} amenity_ids
 * @property {boolean} allow_pets
 * @property {boolean} disallow_pets
 * @property {boolean} bookable_online
 * @property {boolean} promotion
 * @property {boolean} last_minute
 * @property {import("../../../types/Search").SearchRanking} label_ranking
 * @property {import("../../../types/Search").SearchDatesPreciseMode} dates_precise_mode
 * @property {string} month
 * @property {import("../../../types/Search").SearchDatesMode} dates_mode
 * @property {import("../../../types/Search").SearchDuration} duration
 * @property {boolean} highlight
 * @property {boolean} new
 * @property {import("@raiden/library-ui/types/Hotel").HotelType[]} types
 * @property {import("@raiden/library-ui/types/Hotel").HotelPaymentMode[]} payment_modes
 * @property {import("@raiden/library-ui/constants/hotels").HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_NEVER | null} oack_mode
 * @property {number[]} ota_types
 * @property {boolean} is_leisure_home
 */

/**
 * @param {object} params
 * @param {undefined | boolean} params.value
 * @param {"allow_pets" | "disallow_pets"} params.name
 * @returns {boolean}
 */
export function getPetSearchFormDefaultValue({ value, name }) {
  if (name === "disallow_pets") {
    if (value === undefined) {
      return false;
    }

    return !value;
  }
  return getBoolean(value) ?? false;
}

// #region getSearchFormDefaultValues
/**
 * @param {object} params
 * @param {import("@raiden/library-ui/types/Campaign").Campaign} [params.campaign]
 * @param {import("@raiden/library-ui/types/Region").Region} [params.region]
 * @param {import("@raiden/library-ui/types/Departement").Departement} [params.department]
 * @returns {SearchFormValues}
 */
export function getSearchFormDefaultValues({ campaign, region, department }) {
  const typesRaw =
    getStringArray(getCampaignAttribute({ campaign, name: "types" })) ?? [];

  /** @type {SearchFormValues} */
  const defaultValues = {
    location: {
      place: "",
      region_code: region?.code ?? "",
      department_code: department?.code ?? "",
      legacy_region_code: "",
      map: "",
    },
    checkin:
      getString(getCampaignAttribute({ campaign, name: "checkin" })) ?? "",
    checkout:
      getString(getCampaignAttribute({ campaign, name: "checkout" })) ?? "",
    min_checkin:
      getString(getCampaignAttribute({ campaign, name: "min_checkin" })) ?? "",
    max_checkin:
      getString(getCampaignAttribute({ campaign, name: "max_checkin" })) ?? "",
    min_checkout:
      getString(getCampaignAttribute({ campaign, name: "min_checkout" })) ?? "",
    max_checkout:
      getString(getCampaignAttribute({ campaign, name: "max_checkout" })) ?? "",
    min_duration:
      getString(getCampaignAttribute({ campaign, name: "min_duration" })) ?? "",
    max_duration:
      getString(getCampaignAttribute({ campaign, name: "max_duration" })) ?? "",
    adults: getNumber(getCampaignAttribute({ campaign, name: "adults" })) ?? 0,
    children:
      getNumber(getCampaignAttribute({ campaign, name: "children" })) ?? 0,
    babies: getNumber(getCampaignAttribute({ campaign, name: "babies" })) ?? 0,
    pets: getNumber(getCampaignAttribute({ campaign, name: "pets" })) ?? 0,
    min_price:
      getString(
        getNumber(getCampaignAttribute({ campaign, name: "min_price" })),
      ) ?? "",
    max_price:
      getString(
        getNumber(getCampaignAttribute({ campaign, name: "max_price" })),
      ) ?? "",
    bedrooms:
      getNumber(getCampaignAttribute({ campaign, name: "bedrooms" })) ?? "",
    beds: getNumber(getCampaignAttribute({ campaign, name: "beds" })) ?? "",
    bathrooms:
      getNumber(getCampaignAttribute({ campaign, name: "bathrooms" })) ?? "",
    sort: "best",
    amenity_ids:
      getNumberArray(getCampaignAttribute({ campaign, name: "amenity_ids" })) ??
      [],
    allow_pets: getPetSearchFormDefaultValue({
      value: getCampaignAttribute({ campaign, name: "allow_pets" }),
      name: "allow_pets",
    }),
    disallow_pets: getPetSearchFormDefaultValue({
      value: getCampaignAttribute({ campaign, name: "allow_pets" }),
      name: "disallow_pets",
    }),
    bookable_online:
      getBoolean(getCampaignAttribute({ campaign, name: "bookable_online" })) ??
      false,
    promotion:
      getBoolean(getCampaignAttribute({ campaign, name: "promotion" })) ??
      false,
    last_minute:
      getBoolean(getCampaignAttribute({ campaign, name: "last_minute" })) ??
      false,
    label_ranking:
      SEARCH_RANKING_VALUES.find(
        (ranking) =>
          ranking ===
          getNumber(getCampaignAttribute({ campaign, name: "ranking" })),
      ) ?? SEARCH_RANKING_VALUE_0,
    dates_precise_mode: SEARCH_DATES_PRECISE_MODES_VALUE_PRECISE,
    month: "",
    dates_mode: SEARCH_DATES_MODES_VALUE_PRECISE,
    duration: SEARCH_DURATIONS_VALUE_1_WEEK,
    highlight:
      getBoolean(getCampaignAttribute({ campaign, name: "highlight" })) ??
      false,
    new: getBoolean(getCampaignAttribute({ campaign, name: "new" })) ?? false,
    types: HOTELS_TYPES_VALUES.filter((type) => typesRaw.includes(type)),
    payment_modes: [],
    oack_mode: null,
    ota_types: [],
    is_leisure_home: false,
  };
  return defaultValues;
}
/**
 * Permet au retour de la response de ne renvoyer que les types qui ont des résultats.
 * Dans le cadre d'une campaign tous les types sont envoyés, mais certains n'ont potentiellement pas de résultats.
 * Si on désélectionne des types, on on peut obtenir des résultats vides, car les types qui n'ont pas de résultat
 * sont toujours envoyés, et comme ils ne s'affichent pas, impossible de les désélectionner.
 * voir issue #6701 sur GH
 * @param {object} params
 * @param {import("@raiden/library-ui/types/Hotel").HotelType[]} params.types
 * @param {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/SearchHotel").SearchHotel[]>} [params.searchHotelResponse]
 * @returns {import("@raiden/library-ui/types/Hotel").HotelType[]}
 */
function getTypes({ types, searchHotelResponse }) {
  if (searchHotelResponse) {
    const hotel_types = searchHotelResponse.meta.hotel_types;
    if (hotel_types && types && (types || []).length > 0) {
      /** @type {import("@raiden/library-ui/types/Hotel").HotelType[]} */
      const _type = [];

      for (const type of types) {
        if (hotel_types[type] > 0) {
          _type.push(type);
        }
      }

      return _type;
    }
  }

  return types;
}

/**
 * @param {object} param0
 * @param {boolean} param0.allow_pets
 * @param {boolean} param0.disallow_pets
 * @returns {boolean | undefined}
 */
export function getPetValue({ allow_pets, disallow_pets }) {
  if (allow_pets || disallow_pets) {
    return allow_pets ? allow_pets : !disallow_pets;
  }
  return undefined;
}

// #region generateUrl
/**
 * @param {object} params
 * @param {SearchFormValues} params.values
 * @param {import("@raiden/library-ui/types/Campaign").Campaign} [params.campaign]
 * @param {number} params.key
 * @param {any} params.previousPage
 * @param {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/SearchHotel").SearchHotel[]>} [params.searchHotelResponse]
 * @returns {string | null}
 */
export function generateUrl({
  values,
  campaign,
  key,
  previousPage,
  searchHotelResponse,
}) {
  const place = values.location.place;
  const region_code = values.location.region_code;
  const department_code = values.location.department_code;
  const legacy_region_code = values.location.legacy_region_code;
  const campaignLat = campaign?.localization?.lat;
  const campaignLng = campaign?.localization?.lng;
  const placeLat = typeof place === "object" ? place.latitude : undefined;
  const placeLng = typeof place === "object" ? place.longitude : undefined;
  const mapLat =
    typeof values.location.map === "object"
      ? values.location.map.latitude
      : undefined;
  const mapLng =
    typeof values.location.map === "object"
      ? values.location.map.longitude
      : undefined;
  const mapRadius =
    typeof values.location.map === "object"
      ? values.location.map.radius
      : undefined;

  // Si on a une région ou un département, on ajoute le field slideshows.object pour récupérer l'object permettant de construire l'url dans le bouton "Découvrir"
  const fields =
    region_code !== "" || department_code !== "" || legacy_region_code !== ""
      ? ["slideshows.object"]
      : [];

  const queryParams = {
    adults: values.adults || undefined,
    children: values.children,
    babies: values.babies,
    pets: values.pets,
    min_price: values.min_price,
    max_price: values.max_price,
    bedrooms: values.bedrooms,
    beds: values.beds,
    bathrooms: values.bathrooms,
    amenity_ids: values.amenity_ids,
    allow_pets: getPetValue({
      allow_pets: values.allow_pets,
      disallow_pets: values.disallow_pets,
    }),
    bookable_online: values.bookable_online,
    promotion: values.promotion,
    last_minute: values.last_minute,
    label_ranking: values.label_ranking,
    lat: campaignLat ?? placeLat ?? mapLat,
    lng: campaignLng ?? placeLng ?? mapLng,
    radius: campaign?.localization?.radius
      ? Math.round(Number(campaign?.localization?.radius)).toString()
      : mapRadius,
    points: campaign?.localization?.points,
    region_code: region_code !== "" ? region_code : undefined,
    legacy_region_code:
      legacy_region_code !== "" ? legacy_region_code : undefined,
    department_code: department_code !== "" ? department_code : undefined,
    highlight: values.highlight,
    new: values.new,
    types: getTypes({ types: values.types, searchHotelResponse }),
    payment_modes: values.payment_modes,
    oack_mode: values.oack_mode,
    ota_types: values.ota_types,
    is_leisure_home: values.is_leisure_home,
    sort: values.sort,
    per_page: 15,
    cursor: previousPage?.meta?.next,
    fields,
  };

  // si au moins une date est définie dans la campagne
  if (campaign && isAnyDateAttributeSetInCampaign({ campaign })) {
    // alors on utilise les dates de la campagne
    queryParams.checkin = values.checkin;
    queryParams.checkout = values.checkout;
    queryParams.min_checkin = values.min_checkin;
    queryParams.max_checkin = values.max_checkin;
    queryParams.min_checkout = values.min_checkout;
    queryParams.max_checkout = values.max_checkout;
    queryParams.min_duration = values.min_duration;
    queryParams.max_duration = values.max_duration;
  } else {
    // sinon on utilise les dates du formulaire
    switch (values.dates_mode) {
      // dates précises
      case SEARCH_DATES_MODES_VALUE_PRECISE: {
        if (values.checkin !== "" && values.checkout !== "") {
          const offset =
            SEARCH_DATES_PRECISE_MODES[values.dates_precise_mode].offset;
          // on applique un offset aux dates si nécessaire
          if (offset > 0) {
            // on borne la date de checkin à aujourd'hui au minimum
            let minCheckin = dayjs(values.checkin).subtract(offset, "day");
            if (minCheckin.isBefore(dayjs(), "day")) {
              minCheckin = dayjs();
            }
            queryParams.min_checkin = minCheckin.format("YYYY-MM-DD");
            queryParams.max_checkin = dayjs(values.checkin)
              .add(offset, "day")
              .format("YYYY-MM-DD");
            let minCheckout = dayjs(values.checkout).subtract(offset, "day");
            if (minCheckout.isBefore(dayjs(), "day")) {
              minCheckout = dayjs();
            }
            queryParams.min_checkout = minCheckout.format("YYYY-MM-DD");
            queryParams.max_checkout = dayjs(values.checkout)
              .add(offset, "day")
              .format("YYYY-MM-DD");
          } else {
            queryParams.checkin = values.checkin;
            queryParams.checkout = values.checkout;
          }

          // recupère le nombre de nuit entre checkin et checkout pour définir la durée
          const duration = dateGetDaysCount({
            from: values.checkin,
            to: values.checkout,
          });

          queryParams.min_duration = duration;
          queryParams.max_duration = duration;
        }
        break;
      }
      // dates flexibles
      case SEARCH_DATES_MODES_VALUE_FLEXIBLE: {
        const duration = SEARCH_DURATIONS[values.duration];
        queryParams.min_duration = duration.minDuration;
        queryParams.max_duration = duration.maxDuration;
        if (values.month !== "") {
          const selectedMonth = dayjs(values.month);
          queryParams.min_checkin = selectedMonth
            .startOf("month")
            .subtract(duration.minDuration, "day")
            .format("YYYY-MM-DD");
          queryParams.max_checkin = selectedMonth
            .endOf("month")
            .add(duration.minDuration, "day")
            .format("YYYY-MM-DD");
        }
        break;
      }
    }
  }

  if (key > 0 && previousPage === null) {
    return null;
  }
  return generateApiUrl({
    id: "@search.hotels",
    query: encodeQuery(queryParams, {
      useEmptySpacesForNumerics: false,
    }),
  });
}

// #region getValues
/**
 *
 * @param {object} param0
 * @param {object} param0.parsedQuery
 * @param {SearchFormValues} param0.defaultValues
 * @param {import("../../Places/Search").PlacesSearchValue} param0.location
 * @returns {SearchFormValues}
 */
export function getValues({ parsedQuery, defaultValues, location }) {
  const checkin =
    getValidDate({ value: getString(parsedQuery.checkin) }) ??
    defaultValues.checkin;
  const checkout =
    getValidDate({ value: getString(parsedQuery.checkout) }) ??
    defaultValues.checkout;
  const minCheckin = defaultValues.min_checkin;
  const maxCheckin = defaultValues.max_checkin;
  const minCheckout = defaultValues.min_checkout;
  const maxCheckout = defaultValues.max_checkout;
  const minDuration = defaultValues.min_duration;
  const maxDuration = defaultValues.max_duration;
  const adults = getNumber(parsedQuery.adults) ?? defaultValues.adults;
  const children = getNumber(parsedQuery.children) ?? defaultValues.children;
  const babies = getNumber(parsedQuery.babies) ?? defaultValues.babies;
  const pets = getNumber(parsedQuery.pets) ?? defaultValues.pets;
  const minPrice = getString(parsedQuery.min_price) ?? defaultValues.min_price;
  const maxPrice = getString(parsedQuery.max_price) ?? defaultValues.max_price;
  const bedrooms = getNumber(parsedQuery.bedrooms) ?? defaultValues.bedrooms;
  const beds = getNumber(parsedQuery.beds) ?? defaultValues.beds;
  const bathrooms = getNumber(parsedQuery.bathrooms) ?? defaultValues.bathrooms;
  const amenityIds =
    getNumberArray(parsedQuery.amenity_ids) ?? defaultValues.amenity_ids;
  const allowPets =
    getBoolean(parsedQuery.allow_pets) ?? defaultValues.allow_pets;
  const disallowPets =
    getBoolean(parsedQuery.disallow_pets) ?? defaultValues.disallow_pets;
  const bookableOnline =
    getBoolean(parsedQuery.bookable_online) ?? defaultValues.bookable_online;
  const promotion =
    getBoolean(parsedQuery.promotion) ?? defaultValues.promotion;
  const lastMinute =
    getBoolean(parsedQuery.last_minute) ?? defaultValues.last_minute;
  const ranking =
    SEARCH_RANKING_VALUES.find((ranking) => ranking === parsedQuery.ranking) ??
    defaultValues.label_ranking;
  const datePreciseMode =
    SEARCH_DATES_PRECISE_MODES_VALUES.find(
      (mode) => mode === getNumber(parsedQuery.dates_precise_mode),
    ) ?? defaultValues.dates_precise_mode;
  const month = getMonth({
    value: getString(parsedQuery.month) ?? defaultValues.month,
  });
  const datesModes =
    SEARCH_DATES_MODES_VALUES.find(
      (mode) => mode === getString(parsedQuery.dates_mode),
    ) ?? defaultValues.dates_mode;
  const duration =
    SEARCH_DURATIONS_VALUES.find(
      (duration) => duration === getString(parsedQuery.duration),
    ) ?? defaultValues.duration;
  const highlight =
    getBoolean(parsedQuery.highlight) ?? defaultValues.highlight;
  const new_ = getBoolean(parsedQuery.new) ?? defaultValues.new;
  const _types = getStringArray(parsedQuery.types) ?? defaultValues.types;
  const types = HOTELS_TYPES_VALUES.filter((type) => _types.includes(type));
  const payment_modes = defaultValues.payment_modes;
  const oack_mode = defaultValues.oack_mode;
  const ota_types = defaultValues.ota_types;
  const is_leisure_home = defaultValues.is_leisure_home;
  const sort =
    SEARCH_SORTS_VALUES.find((mode) => mode === getString(parsedQuery.sort)) ??
    defaultValues.sort;

  /** @type {SearchFormValues} */
  return {
    location,
    checkin,
    checkout,
    min_checkin: minCheckin,
    max_checkin: maxCheckin,
    min_checkout: minCheckout,
    max_checkout: maxCheckout,
    min_duration: minDuration,
    max_duration: maxDuration,
    adults,
    children,
    babies,
    pets,
    min_price: minPrice,
    max_price: maxPrice,
    bedrooms,
    beds,
    bathrooms,
    amenity_ids: amenityIds,
    bookable_online: bookableOnline,
    allow_pets: allowPets,
    disallow_pets: disallowPets,
    promotion,
    last_minute: lastMinute,
    label_ranking: ranking,
    dates_precise_mode: datePreciseMode,
    month,
    dates_mode: datesModes,
    duration,
    highlight,
    new: new_,
    types: types,
    oack_mode,
    payment_modes,
    ota_types,
    is_leisure_home,
    sort,
  };
}

// #region component
export const SearchHandler = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Campaign").Campaign} [campaign]
   * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/SearchHotel").SearchHotel[], import("@raiden/library-ui/types/SearchHotel").SearchHotelMeta | Array>} [searchHotelResponse]
   * @property {(params: {searchInfiniteContext: import("@raiden/library-ui/contexts/SearchInfinite/SearchInfiniteProvider").SearchInfiniteContextValue<SearchFormValues>, setAutoSubmit: import("react").Dispatch<import("react").SetStateAction<boolean>>, updatePositionWhenDragging: boolean, setUpdatePositionWhenDragging: import("react").Dispatch<import("react").SetStateAction<boolean>>}) => import("react").ReactElement} children
   * @property {import("./Handler").SearchFormValues} initialFields
   * @property {import("./Handler").SearchFormValues} defaultValues
   */
  /**
   * @param {Props} props
   */
  function SearchHandler({
    campaign,
    children: childrenRenderFunction,
    searchHotelResponse,
    initialFields,
    defaultValues,
  }) {
    const [autoSubmit, setAutoSubmit] = useState(true);

    const [updatePositionWhenDragging, setUpdatePositionWhenDragging] =
      useState(campaign?.localization !== undefined ? false : true);

    const searchInfiniteContext = useSearchInfinite({
      autoSubmit,
      generateUrl: (fields, key, previousPage) =>
        generateUrl({
          values: fields,
          campaign,
          key,
          previousPage,
          searchHotelResponse,
        }),
      useRouterUrl: true,
      defaultValues,
      initialFields,
      keepPreviousData: true,
      fallbackData: searchHotelResponse ? [searchHotelResponse] : undefined,
    });

    // #region return
    return (
      <SearchInfiniteProvider {...searchInfiniteContext}>
        {childrenRenderFunction({
          searchInfiniteContext,
          setAutoSubmit,
          updatePositionWhenDragging,
          setUpdatePositionWhenDragging,
        })}
      </SearchInfiniteProvider>
    );
  },
);
