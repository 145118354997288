import {
  Box,
  Button,
  Flex,
  IconButton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import CalendarGrid from "@raiden/library-ui/components/DatePicker/CalendarGrid";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import FormObserver from "@raiden/library-ui/components/ReactHookForm/FormObserver";
import { RGrid } from "@raiden/library-ui/components/RGrid";
import { StandAloneDateRangePicker } from "@raiden/library-ui/components/StandAloneDateRangePicker";
import dayjs from "dayjs";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { DurationPicker } from "../../../components/Form/DurationPicker";
import { MonthPicker } from "../../../components/Form/MonthPicker";
import { SearchDatesPreciseModePicker } from "../../../components/Form/SearchDatesPreciseModePicker";
import { Icon } from "@raiden/library-ui/components/Icon";
import {
  SEARCH_DATES_MODES_VALUE_FLEXIBLE,
  SEARCH_DATES_MODES_VALUE_PRECISE,
} from "../../../constants/Search";
import { CustomDateInput } from "./CustomDateInput";
import { getCampaignAttribute } from "@raiden/library-ui/helpers/campaigns/getAttribute";

/**
 * @param {object} params
 * @param {string} params.date
 * @param {import("dayjs").Dayjs} params.minDate
 */
function isValidDate({ date, minDate }) {
  const dayjsDate = dayjs(date);
  return (
    date.match(/^\d{4}-\d{2}-\d{2}$/) &&
    dayjsDate.isValid() &&
    dayjsDate.isAfter(minDate.subtract(1, "day"))
  );
}

/**
 * @param {object} params
 * @param {string} params.checkin
 * @param {string} params.checkout
 */
function getDateRange({ checkin, checkout }) {
  const isCheckinValid = isValidDate({ date: checkin, minDate: dayjs() });
  const isCheckoutValid = isValidDate({
    date: checkout,
    minDate: isCheckinValid ? dayjs(checkin) : dayjs(),
  });
  if (isCheckinValid && isCheckoutValid) {
    return {
      start: checkin,
      end: checkout,
    };
  }
  if (isCheckinValid) {
    return {
      start: checkin,
      end: checkin,
    };
  }
  return {
    start: dayjs().format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  };
}

const MONTHS_DISPLAYED_ON_DESKTOP = 2;

// #region compoent
/**
 * @typedef {object} Props
 * @property {import("react-hook-form").UseFormReturn<import("../Search/Handler").SearchFormValues>} form
 * @property {import("@raiden/library-ui/types/Campaign").Campaign | null} campaign
 */
/**
 * @param {Props} props
 */
export function SearchDatesForm({
  form: { control, setValue, getValues },
  campaign,
}) {
  const intl = useIntl();

  const [focusedDate, setFocusedDate] = useState(
    /** @type {string | null} */ (null),
  );

  const minCheckin = useMemo(() => {
    if (campaign) {
      const minCheckin = getCampaignAttribute({
        campaign,
        name: "min_checkin",
      });
      if (typeof minCheckin === "string") {
        const minCheckinDate = dayjs(minCheckin);
        if (minCheckinDate.isValid()) {
          if (minCheckinDate.isBefore(dayjs())) {
            return dayjs().format("YYYY-MM-DD");
          }
          return dayjs(minCheckinDate).format("YYYY-MM-DD");
        }
      }
    }
    return dayjs().format("YYYY-MM-DD");
  }, [campaign]);

  const maxCheckout = useMemo(() => {
    if (campaign) {
      const maxCheckout = getCampaignAttribute({
        campaign,
        name: "max_checkout",
      });
      if (typeof maxCheckout === "string") {
        const maxCheckoutDate = dayjs(maxCheckout);
        if (maxCheckoutDate.isValid()) {
          return dayjs(maxCheckoutDate).format("YYYY-MM-DD");
        }
      }
    }
  }, [campaign]);

  const [monthsDisplayedOnMobile, setMonthsDisplayedOnMobile] = useState(4);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const searchDatesMode = useWatch({ control, name: "dates_mode" });

  const handleClickPrecise = useCallback(() => {
    setValue("dates_mode", SEARCH_DATES_MODES_VALUE_PRECISE, {
      shouldDirty: true,
    });
  }, [setValue]);

  const handleClickFlexible = useCallback(() => {
    setValue("dates_mode", SEARCH_DATES_MODES_VALUE_FLEXIBLE, {
      shouldDirty: true,
    });
  }, [setValue]);

  const handleLoadMoreMonths = useCallback(() => {
    setMonthsDisplayedOnMobile((prev) => prev + 4);
  }, []);

  // #region return
  return (
    <Tabs
      index={searchDatesMode === SEARCH_DATES_MODES_VALUE_FLEXIBLE ? 1 : 0}
      isLazy={true}
      lazyBehavior="unmount"
      variant="line">
      <TabList
        justifyContent={{ base: "center", lg: "flex-start" }}
        whiteSpace="nowrap">
        <Tab onClick={handleClickPrecise}>
          <FormattedMessage defaultMessage="Dates précises" />
        </Tab>

        <Tab onClick={handleClickFlexible}>
          <FormattedMessage defaultMessage="Dates fléxibles" />
        </Tab>
      </TabList>

      <TabPanels mt="1rem">
        <TabPanel p="0">
          <Stack spacing="1rem">
            <RGrid gap="1rem" display={{ base: "none", md: "grid" }}>
              <FormControlRHF
                control={control}
                name="checkin"
                rules={{
                  onChange: (e) => {
                    const checkin = e.target["value"];
                    if (isValidDate({ date: checkin, minDate: dayjs() })) {
                      setFocusedDate(checkin);
                    }
                    const checkout = getValues("checkout");
                    if (
                      checkin === "" ||
                      dayjs(checkin).isAfter(dayjs(checkout))
                    ) {
                      setValue("checkout", "", { shouldDirty: true });
                    }
                  },
                }}
                label={intl.formatMessage({ defaultMessage: "Du" })}
                renderWithFormControl={(field) => (
                  <CustomDateInput {...field} minDate={minCheckin} />
                )}
              />

              <FormObserver control={control} name="checkin">
                {(checkin) => (
                  <FormControlRHF
                    control={control}
                    name="checkout"
                    label={intl.formatMessage({ defaultMessage: "Au" })}
                    isDisabled={checkin === ""}
                    renderWithFormControl={(field) => (
                      <CustomDateInput {...field} minDate={checkin} />
                    )}
                  />
                )}
              </FormObserver>
            </RGrid>

            <FormObserver control={control} name="checkin">
              {(checkin) => (
                <FormObserver control={control} name="checkout">
                  {(checkout) => (
                    <StandAloneDateRangePicker
                      value={getDateRange({ checkin, checkout })}
                      minCheckin={minCheckin}
                      maxCheckout={maxCheckout}
                      focusedDate={focusedDate ?? undefined}
                      onChange={(e) => {
                        if (typeof e.target["value"] === "string") {
                          return;
                        }
                        const start = e.target["value"]?.start;
                        const end = e.target["value"]?.end;
                        if (start && end) {
                          setValue("checkin", start, {
                            shouldDirty: true,
                          });
                          setValue("checkout", end, {
                            shouldDirty: true,
                          });
                        }
                      }}
                      visibleDuration={{
                        months: isMobile
                          ? monthsDisplayedOnMobile
                          : MONTHS_DISPLAYED_ON_DESKTOP,
                      }}
                      name="undefined"
                      isDisabled={false}
                      isInvalid={false}
                      isRequired={false}
                      onBlur={() => {}}
                      render={({ state, calendar, ref }) => {
                        const start = state.visibleRange.start;

                        /**
                         * @param {object} params
                         * @param {string} params.ymd
                         * @returns {boolean}
                         */
                        function isDisabled({ ymd }) {
                          if (campaign === null) {
                            return false;
                          }
                          const currentCellDate = dayjs(ymd);
                          if (state.anchorDate) {
                            // user is setting the checkout date
                            const anchorDate = dayjs(
                              state.anchorDate.toString(),
                            );
                            let diff = Math.abs(
                              currentCellDate.diff(anchorDate, "day"),
                            );
                            const maxDuration = getCampaignAttribute({
                              campaign,
                              name: "max_duration",
                            });
                            const minDuration = getCampaignAttribute({
                              campaign,
                              name: "min_duration",
                            });
                            if (
                              (typeof maxDuration === "string" &&
                                diff > Number(maxDuration)) ||
                              (typeof minDuration === "string" &&
                                diff < Number(minDuration))
                            ) {
                              return true;
                            }
                            const minCheckout = getCampaignAttribute({
                              campaign,
                              name: "min_checkout",
                            });
                            if (typeof minCheckout === "string") {
                              const minCheckoutDate = dayjs(minCheckout);
                              if (
                                minCheckoutDate.isValid() &&
                                currentCellDate.isBefore(minCheckoutDate)
                              ) {
                                return true;
                              }
                            }
                          } else {
                            // user is setting the checkin date
                            const maxCheckin = getCampaignAttribute({
                              campaign,
                              name: "max_checkin",
                            });
                            if (typeof maxCheckin === "string") {
                              const maxCheckinDate = dayjs(maxCheckin);
                              if (
                                maxCheckinDate.isValid() &&
                                currentCellDate.isAfter(maxCheckinDate)
                              ) {
                                return true;
                              }
                            }
                          }
                          return false;
                        }

                        return (
                          <>
                            <Flex ref={ref} minH="265px" w="full">
                              <IconButton
                                icon={
                                  <Icon icon="ms_chevron_left" size="1.5rem" />
                                }
                                onClick={() => {
                                  state.focusPreviousPage();
                                }}
                                isDisabled={calendar.prevButtonProps.isDisabled}
                                alignSelf="center"
                                size="lg"
                                variant="outline"
                                borderRadius="full"
                                display={{ base: "none", md: "flex" }}
                                aria-label={intl.formatMessage({
                                  defaultMessage: "Mois précédent",
                                })}
                              />

                              <Flex
                                alignSelf="flex-start"
                                flexDirection={{
                                  base: "column",
                                  md: "row",
                                }}
                                gap="1rem"
                                w="full"
                                px="1rem">
                                {Array.from({
                                  length: isMobile
                                    ? monthsDisplayedOnMobile
                                    : MONTHS_DISPLAYED_ON_DESKTOP,
                                }).map((_, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <VStack>
                                        <Text
                                          variant="h5"
                                          textTransform="capitalize">
                                          <FormattedDate
                                            value={start
                                              .add({ months: index })
                                              .toString()}
                                            month="long"
                                            year="numeric"
                                            timeZone="UTC"
                                          />
                                        </Text>

                                        <CalendarGrid
                                          state={state}
                                          offset={{
                                            months: index,
                                          }}
                                          isDisabled={isDisabled}
                                        />
                                      </VStack>
                                    </Fragment>
                                  );
                                })}
                              </Flex>

                              <IconButton
                                icon={
                                  <Icon icon="ms_chevron_right" size="1.5rem" />
                                }
                                onClick={() => {
                                  state.focusNextPage();
                                }}
                                isDisabled={calendar.nextButtonProps.isDisabled}
                                alignSelf="center"
                                size="lg"
                                variant="outline"
                                borderRadius="full"
                                display={{ base: "none", md: "flex" }}
                                aria-label={intl.formatMessage({
                                  defaultMessage: "Mois Suivant",
                                })}
                              />
                            </Flex>

                            <Flex
                              px="1rem"
                              display={{ base: "flex", md: "none" }}>
                              <Button
                                onClick={handleLoadMoreMonths}
                                variant="outline"
                                flexGrow={1}>
                                <Text>
                                  <FormattedMessage defaultMessage="Charger plus" />
                                </Text>
                              </Button>
                            </Flex>
                          </>
                        );
                      }}
                    />
                  )}
                </FormObserver>
              )}
            </FormObserver>

            <Box
              position="sticky"
              bottom="0"
              borderTopWidth="1px"
              backgroundColor="#fff"
              p="1rem">
              <FormControlRHF
                control={control}
                name="dates_precise_mode"
                render={(field) => <SearchDatesPreciseModePicker {...field} />}
              />
            </Box>
          </Stack>
        </TabPanel>

        <TabPanel py="0" px="0">
          <Stack spacing="1.5rem">
            <Box>
              <Text variant="h5">
                <FormattedMessage defaultMessage="Vous n'avez pas décidé d'un jour de départ ?" />
              </Text>

              <Text variant="text-xs" color="gray.500" mt="0.125rem">
                <FormattedMessage defaultMessage="Choisissez la durée et le moment de votre séjour" />
              </Text>
            </Box>

            <FormControlRHF
              control={control}
              name="duration"
              label={intl.formatMessage({
                defaultMessage: "Je souhaite partir...",
              })}
              renderWithFormControl={(field) => <DurationPicker {...field} />}
            />

            <FormControlRHF
              control={control}
              name="month"
              label={intl.formatMessage({
                defaultMessage: "Sur la période...",
              })}
              renderWithFormControl={(field) => <MonthPicker {...field} />}
            />
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
